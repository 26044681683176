<template>
  <v-container>
    <v-toolbar flat class="ma-0 pa-0"
      v-show="loggedIn === 1">
      <v-spacer></v-spacer>
      <v-col v-show="showFind" cols="4" sm="4" md="4">
        <v-text-field
          class="mt-2 pt-2"
          v-show="showFind"
          v-model=searchValue
          ref="text_find"
          label="Buscando por.."
          prepend-inner-icon="mdi-magnify"
          append-outer-icon="mdi-close"
          @click:append-outer="cancelFind()"
          v-on:keyup="keymonitor">
        </v-text-field>
      </v-col>
      <v-btn
        v-show="showBtnPrint"
        @click.stop="btnPrint()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-printer
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >imprimir
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnNew"
        @click.stop="btnNew()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-file-plus
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >nuevo
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnEdit"
        @click.stop="btnEdit()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-pencil
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >editar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnCancel"
        @click.stop="btnCancel()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-close
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >Cancelar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnSave"
        @click.stop="btnSave()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-check
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >salvar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnFind"
        @click.stop="btnFind()"
        class="ma-1 pa-1"
        :plain="!this.showFind ? true:false"
        :color="this.showFind ? 'primary':''">
        <v-icon large color="blue darken-4"
          >mdi-magnify
        </v-icon>
        <span class="" v-show="screenSize != 'xs'">
          {{ showFind ? 'Buscando' : 'Buscar' }}
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnClose"
        @click.stop="btnClose()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-window-close
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >cerrar
        </span>
      </v-btn>
  </v-toolbar>
  <v-card max-width="95%" class="mx-auto" flat v-show="loggedIn === 1">
    <v-divider></v-divider>
    <v-list
      subheader
      dense
      flat
      >
      <!-- v-subheader>Hangout notifications</v-subheader -->
      <v-list-item-group
        v-model="settings">
        <!-- v-subheader>{{title}}</v-subheader -->
        <template v-for="(item, index) in filteredRecipes">
          <v-divider :key="index"></v-divider>
          <v-list-item
            :key="item.title"
            @click.stop="takeItem(item)">
            <v-list-item-avatar>
              <v-img :src="require('@/assets/avatar.png')"
                class="my-3" contain height="100%" >
              </v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="item.name + ' ' + getFirstLetters(item.name)"></v-list-item-title>
              <v-list-item-title class="text--disabled" v-html="'MAIL ' + item.personalemail"></v-list-item-title>
              <v-list-item-subtitle class="text--disabled" v-html="'MOVIL ' + item.movil"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text v-text="item.action"></v-list-item-action-text>
              <v-icon
                v-if="item.active!=='1'"
                color="grey lighten-1"
                >
                mdi-star-outline
              </v-icon>
              <v-icon
                v-else
                color="yellow darken-3"
                >
                mdi-star
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-card>
  </v-container>
</template>
<script>
  export default {
    name: 'ProfileForm',
    props: {
      //
    },
    components: {
      //
    },
    data: () => ({
      searchValue: null,
      showFind: false,
      //
      showBtnSave: false,
      showBtnCancel: false,
      showBtnEdit: false,
      showBtnFind: true,
      showBtnPrint: true,
      showBtnNew: false,
      showBtnClose: true,
      // *** ascending: false,
      // *** sortBy: 'alphabetically',
      // *** maxCookingTime: null,
      settings: [],
      items: [
        { "id": 10, "name": "Name User Demo", "email": "user", "firstname": "Name", "midname": "User", "lastname": "Demo", "comment": "Usuario Pruebas", "personalemail": "userdemo@gmails.com", "movil": "98-3476-5432", "password": "", "action": "Activo", "active": '1', "avatar": "PNG" }
      ],
      itemDefault:{
        "id": 0,
        "name": "",
        "email": "",
        "firstname": "",
        "midname": "",
        "lastname": "",
        "comment": "",
        "personalemail": "",
        "movil": "",
        "password": "",
        "action": "Activo",
        "active": '1',
        "avatar": "PNG" 
      },
      //
    }),
    methods: {
      initialize: async function () {
        await this.usersList();
        let keyCodes = await this.items.map(individual => {
          return `${individual.email.toLowerCase()}`
        });
        this.$store.dispatch('loadKeyUniqueCodes', keyCodes);
      },
      btnPrint: async function () {
        //
      },
      btnClose: async function () {
        await this.$router.push({name: 'Logged', params: {}});
      },
      btnNew: async function () {
        this.$emit('setItemRow', this.itemDefault);
        this.$emit('tabChange', 2);
      },
      btnEdit: async function () {
        //
      },
      btnCancel: async function () {
        //
      },
      btnSave: async function () {
        //
      },
      btnFind: async function () {
        this.showFind = await !this.showFind;
        if(await this.showFind){
          await setTimeout(() => {
            this.$refs.text_find.focus();
          }, 500);
        } else {
          this.searchValue = await null;
        }
      },
      cancelFind: async function () {
        this.searchValue = await null;
        this.showFind = await false;
      },
      keymonitor: function (event) {
        if (event.key === 'Enter') {
          // *** 
        }
      },
      usersList: async function () {
        this.items = await [];
        await this.getList()
      },
      getList: async function () {
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        if (await token) {
          let apiRoute = await 'userall';
          let formData = await {};
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          this.items = await dataResponse.data
        }
      },
      takeItem: async function (val) {
        let editedIndex = await this.items.indexOf(val);
        let a = await this.keyUniqueCodes;
        await a.splice(editedIndex, 1);
        await this.$store.dispatch('loadKeyUniqueCodes', a);
        await this.$emit('setItemRow', val);
        await this.$emit('tabChange', 2);
      },
      processData: function () {
        //
      }
    },
    created () {
      //
    },
    mounted () {
      this.initialize();
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      filteredRecipes() {
        let tempRecipes = this.items;
        // Process search input
        if (this.searchValue !== '' && this.searchValue) {
            tempRecipes = tempRecipes.filter((item) => {
              if (item.name && item.personalemail){
                return (
                  item.name
                  .toUpperCase()
                  .includes(this.searchValue.toUpperCase()) ||
                  item.personalemail
                  .toUpperCase()
                  .includes(this.searchValue.toUpperCase()) ||
                  item.movil
                  .toUpperCase()
                  .includes(this.searchValue.toUpperCase())
                )
              }
            })
        }

        // **
        /*
        // Filter out by cooking time
        if (this.maxCookingTime)
          tempRecipes = tempRecipes.filter((item) => {
            return (item.cookingTime <= this.maxCookingTime)
        })
        // Sort by alphabetical order
        
        tempRecipes = tempRecipes.sort((a, b) => {
          if (this.sortBy == 'alphabetically') {
            let fa = a.title.toLowerCase(), fb = b.title.toLowerCase()
            if (fa < fb) {
              return -1
            }
            if (fa > fb) {
              return 1 
            }
            return 0
            // Sort by cooking time
          } else if (this.sortBy == 'cookingTime') {
              return a.cookingTime - b.cookingTime
          }
        })
        
        // Show sorted array in descending or ascending order
        if (!this.ascending) {
          tempRecipes.reverse()
        }
        */
        // ***
        return tempRecipes
      }
    }
  }
</script>
