<template>
  <v-container>

    <v-toolbar flat class="ma-0 pa-0"
      v-show="loggedIn === 1">
      <v-spacer></v-spacer>
      <v-col v-show="showFind" cols="4" sm="4" md="4">
        <v-text-field
          class="mt-2 pt-2"
          v-show="showFind"
          v-model=searchValue
          ref="text_find"
          label="Buscando por.."
          prepend-inner-icon="mdi-magnify"
          append-outer-icon="mdi-close"
          @click:append-outer="cancelFind()"
          v-on:keyup="keymonitor">
        </v-text-field>
      </v-col>
      <v-btn
        v-show="showBtnPrint"
        @click.stop="btnPrint()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-printer
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >imprimir
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnNew"
        @click.stop="btnNew()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-file-plus
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >nuevo
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnEdit"
        @click.stop="btnEdit()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-pencil
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >editar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnCancel"
        @click.stop="btnCancel()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-close
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >Cancelar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnSave"
        @click.stop="btnSave()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-check
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >salvar
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnFind"
        @click.stop="btnFind()"
        class="ma-1 pa-1"
        :plain="!this.showFind ? true:false"
        :color="this.showFind ? 'primary':''"
        >
        <v-icon large color="blue darken-4"
          >mdi-magnify
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >
          {{ showFind ? 'Buscando' : 'Buscar' }}
        </span>
      </v-btn>
      <v-btn
        v-show="showBtnClose"
        @click.stop="btnClose()"
        class="ma-1 pa-1" plain>
        <v-icon large color="blue darken-4"
          >mdi-chevron-left
        </v-icon>
        <span class="" v-show="screenSize != 'xs'"
          >cerrar
        </span>
      </v-btn>
    </v-toolbar>

    <v-toolbar flat class="ma-0 pa-0" v-show="loggedIn === 1">
      <v-col cols="2" sm="2" md="2">
        <v-text-field
          v-model="item.id"
          label="ID"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="3" sm="3" md="3">
        <v-text-field
          v-model="item.email"
          label="Usuario"
          disabled
        ></v-text-field>
      </v-col>
      <!-- v-col cols="1" sm="1" md="1">
        <v-text-field
          v-model="item.active"
          label="Activo"
          disabled
        ></v-text-field>
      </v-col -->

      <v-col cols="2" sm="2" md="2">
        <v-text-field v-model="item.active" label="Activo" disabled>
          <template v-slot:append>
            <v-icon
              :color="item.active === '1' ? 'yellow darken-3' : 'grey lighten-1'">
              {{ item.active === '1' ? 'mdi-star' : 'mdi-star-outline' }} 
            </v-icon>
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="10" sm="4" md="4">
        <v-text-field
          v-model="item.name"
          label="Nombre"
          disabled
        ></v-text-field>
      </v-col>
    </v-toolbar>

    <v-layout v-show="isEditing" wrap justify-center>
      <v-flex xs10 sm3 md3>
        <v-checkbox
          v-model="checkAll"
          label="Elegir todos?"
          primary
          hide-details
          :input-value="checkAll"
          :disabled="!isEditing"
          @change="selectAll();"
        ></v-checkbox>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>

      <v-flex xs10 sm10 md10 v-show="mnuItems.length>1">
        <v-layout wrap >
          <v-flex xs10 sm3 md3 v-for="mainOpc in mnuItems" :key="mainOpc.opcId">
            <v-switch v-model="checkedOptions" :label="mainOpc.etiqueta" :value="mainOpc.opcId"
              :input-value="mainOpc.opcId" :id="mainOpc.opcId" :disabled="!isEditing"
              >
            </v-switch>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
  export default {
    name: 'ProfileDtForm',
    props: [
      'item'
    ],
    components: {
      //
    },
    data: () => ({
      isEditing: null,
      isSave: null,
      checkAll: false,
      checkedOptions: [],
      editedOptions: [
      ],
      mnuItems: [
        { opcId: '1', etiqueta: 'Opcion 1' }
      ],
      //
      searchValue: null,
      showFind: false,
      //
      showBtnSave: false,
      showBtnCancel: false,
      showBtnEdit: true,
      showBtnFind: false,
      showBtnPrint: true,
      showBtnNew: false,
      showBtnClose: true,
      //
      //
    }),
    methods: {
      initialize: function () {
        //
      },
      btnPrint: async function () {
        //
      },
      btnClose: async function () {
        this.$emit('tabChange', 1);
      },
      btnNew: async function () {
        //
      },
      btnEdit: async function () {
        //
        // *** this.title = this.title.substring(0,this.titleLength);
        // *** this.title = this.title + ' (EDITANDO REGISTRO)';
        this.showBtnPrint = false;
        this.showBtnEdit = false;
        this.showBtnCancel = true;
        this.showBtnSave = true;
        this.isEditing = true;
        // *** this.editFocus('text_email');
      },
      btnCancel: async function () {
        this.showBtnPrint = true;
        this.showBtnEdit = true;
        this.isEditing = false;
        this.showBtnCancel = false;
        this.showBtnSave = false;
        this.isEditing = false;
        this.checkAll = false;
        this.checkedOptions = this.copyObjects(this.originOptions);
        // *** this.title = this.title.substring(0,this.titleLength);
        // *** this.editedItem = this.copyObjects(this.item);
      },
      btnSave: async function () {
        //
        if(await this.validationFields()){
          //
          const token = await JSON.parse(sessionStorage.getItem('localToken'));
          let formData = await {
            'user_id': this.item.id,
            'checkedOptions': this.checkedOptions
          };
          formData.employee_id = await this.userData.id;
          let apiRoute = await 'profilsave';
          const returnData = await true;
          let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
          // await console.log(dataResponse);
          if (await dataResponse){
            // await console.log(dataResponse);
            this.showBtnSave = await false;
            this.showBtnCancel = await false;
            this.isEditing = await false;
            this.checkAll = await false;
            this.showBtnPrint = await true;
            this.showBtnEdit = await true;
            await this.$store.dispatch('loadMessage', { msg: dataResponse.message, type: 'info' })
          }
          //
        }
        /*
        if(await this.validationFields()){
          const token = await JSON.parse(sessionStorage.getItem('localToken'));
          let formData = await this.editedItem;
          let apiRoute = await formData.id === 0? 'usercreate' : 'userupdate';
          formData.employee_id = await this.userData.id;
          formData.name = (await formData.firstname + ' ' + 
          await formData.midname + ' ' + 
          await formData.lastname).trim();
          if(await formData.password.length === 0 &&
            await formData.id === 0){
            formData.password = await this.makeString(6);
          }
          const returnData = await true;
          if(await apiRoute.trim().length !== 0){
            let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
            // *** console.log(dataResponse);
            if (await dataResponse) {
              if (await dataResponse.data){
                let message = await '';
                let type =  await 'info';
                if(await dataResponse.error === 0){
                  this.isEditing = await false;
                  if(await dataResponse.data.id !== await this.editedItem.id){
                    this.editedItem.id = await dataResponse.data.id;
                  }
                  await this.$emit('setItemRow', this.copyObjects(this.editedItem));
                  this.showBtnSave = await false;
                  this.showBtnCancel = await false;
                  this.showBtnPrint = await true;
                  this.showBtnEdit = await true;
                  // *** this.title = await this.title.substring(0,this.titleLength);
                } else (
                  type = await 'error'
                )
                message = await dataResponse.message;
                await this.$store.dispatch('loadMessage', { msg: message, type: type });
                await this.scrollToTop();
                if(await dataResponse.gotocolumn){
                  if(await dataResponse.gotocolumn.length!==0){
                    await this.getFocus(dataResponse.gotocolumn);
                  }
                }
              }
            }
          }
          
        }
        */
      },
      validationFields: function () {
        let isValid = true;
        return isValid;
      },
      btnFind: async function () {
        this.showFind = await !this.showFind;
        await setTimeout(() => {
          this.$refs.text_find.focus();
        }, 500);
      },
      cancelFind: async function () {
        this.searchValue = await null;
        this.showFind = await false;
      },
      selectAll: function () {
        let selectAll = []
        if ( this.checkAll === true ) {
          let myObj = this.mnuItems
          for (var i = 0; i < myObj.length; i++){
              selectAll[i] = myObj[i].opcId.toString()
          }
        }
        this.checkedOptions = selectAll
      },
      keymonitor: async function (event) {
        if (await event.key === 'Enter') {
          // *** 
        }
      },
      listOptions: async function () {
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        let formData = await {};
        let apiRoute = await 'menuprofile';
        const returnData = await true;
        let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
        // await console.log(dataResponse);
        if (await dataResponse){
          this.mnuItems = await dataResponse.data
        }
      },
      userRules: async function () {
        const token = await JSON.parse(sessionStorage.getItem('localToken'));
        let formData = await {'user_id': this.item.id};
        let apiRoute = await 'profiluser';
        const returnData = await true;
        let dataResponse = await this.callAPI(formData,apiRoute,token,returnData);
        // await console.log(dataResponse);
        if (await dataResponse){
          this.checkedOptions = await dataResponse.data;
          this.originOptions = await this.copyObjects(dataResponse.data);
        }
      },
      processData: function () {
        //
      }
    },
    created () {
      //
    },
    mounted () {
      this.listOptions();
      this.userRules();
      //
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      //
    }
  }
</script>

<style scoped>
.item-icon :deep(.v-icon) {
    margin-right: 10px;
  }
.v-input__icon--append .v-icon { 
    color: purple;
}
</style>
